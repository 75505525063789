<template>
  <div class="ExamStudentScore">
    <ExamDetailsHeader
      ref="ExamDetailsHeader"
      :router-url="$route.query.urlName"
      :query="{
        examId: $route.query.examId,
        paperId: $route.query.examPaperId,
        routerName: $route.query.typeName,
      }"
    ></ExamDetailsHeader>
    <div class="header-box">
      <div class="title"></div>
      <div class="student-info-list">
        <div>
          <p>{{ studentInfo.studentName }}</p>
          <span>考生姓名</span>
        </div>
        <div>
          <p>{{ studentInfo.studentExamId }}</p>
          <span>考生考号</span>
        </div>
        <div>
          <p>
            {{ setallScore() }}
          </p>
          <span>分数</span>
        </div>
        <div>
          <p>{{ $route.query.examPaperId | setName(paperList) }}</p>
          <span>考试科目</span>
        </div>
      </div>
      <div class="header-btn-box">
        <el-button
          type="primary"
          plain
          :disabled="scoreList.length == 0"
          @click="getStudentInfo(2)"
        >
          查看下一个
        </el-button>
        <el-button type="primary" plain @click="markStudentInfo"
          >查看答题卡</el-button
        >
      </div>
    </div>

    <div class="question-cnt">
      <!--客观题 -->
      <div>
        <div class="question-header">
          客观题({{ setScore(1, 1) }}分) 得分：{{ setScore(1, 2) }}分
          <span
            >注：文字高亮为正确答案，选项高亮为学生选择项。<em
              >若试题无高亮情况，则是试题未设置正确答案，请勿调整</em
            ></span
          >
        </div>
        <div class="question-list-box">
          <template v-for="(v, i) in scoreList">
            <div v-if="v.type == 0" :key="i" class="question-list">
              <div class="question-list-title">
                {{ v.examPaperQuestionNumber }}
              </div>
              <el-checkbox-group v-model="v.studentAnswer">
                <template v-if="v.selectType == 3">
                  <template v-for="(m, n) in optionListT">
                    <el-checkbox
                      v-if="n < v.optionCount"
                      :key="n + 'm'"
                      :disabled="setDis(v, m)"
                      :label="m"
                      :class="{ on: setClass(v, m) }"
                    ></el-checkbox>
                  </template>
                </template>
                <template v-else>
                  <template v-for="(m, n) in answerList">
                    <el-checkbox
                      v-if="n < v.optionCount"
                      :key="n + 'm'"
                      :disabled="setDis(v, m)"
                      :label="m"
                      :class="{ on: setClass(v, m) }"
                    ></el-checkbox>
                  </template>
                </template>
              </el-checkbox-group>
            </div>
          </template>
        </div>
      </div>

      <!--主观题 -->
      <div>
        <div class="question-header">
          主观题({{ setScore(2, 1) }}分) 得分：{{ setScore(2, 2) }}分
          <span>
            注：多评下的分项给分的题目总分遵循平均分计算规则，分项之和与小题最终得分可能存在差异，若需对小题成绩进行调整，可直接修改各分项得分！
          </span>
        </div>
        <!-- {{ examInformation.status }} -->
        <template v-for="(v, i) in scoreList">
          <div v-if="v.type == 1" :key="i">
            <div class="question-list-subjective">
              <div class="question-list-title">
                <template v-if="v.isOption == 1">选做题</template
                ><template v-else>主观题</template
                >{{ v.examPaperQuestionNumber }}题. {{ v.score }}分
              </div>
              <div>
                <el-input
                  v-model="v.studentScore"
                  :disabled="getDis(v)"
                  placeholder=""
                  oninput='value = (value.match(/^[0-9]+(\.[0-9]{0,2})?/g) ?? [""])[0]'
                  @blur="v.studentScore = $event.target.value"
                >
                </el-input>
              </div>

              <div
                v-if="!v.pointList || v.pointList.length == 0"
                class="teacher-box"
              >
                <div
                  v-if="getQuestionTeacher(v.examPaperQuestionNumber, 'score1')"
                  class="teacher-list"
                >
                  阅卷老师：一评老师（{{
                    getTeacherVal(
                      v.examPaperQuestionNumber,
                      "score1",
                      "teacherName1"
                    )
                  }}）
                </div>
                <div
                  v-if="getQuestionTeacher(v.examPaperQuestionNumber, 'score2')"
                  class="teacher-list"
                >
                  阅卷老师：二评老师（{{
                    getTeacherVal(
                      v.examPaperQuestionNumber,
                      "score2",
                      "teacherName2"
                    )
                  }}）
                </div>
                <div
                  v-if="getQuestionTeacher(v.examPaperQuestionNumber, 'score3')"
                  class="teacher-list"
                >
                  阅卷老师：三评老师（{{
                    getTeacherVal(
                      v.examPaperQuestionNumber,
                      "score3",
                      "teacherName3"
                    )
                  }}）
                </div>
                <div
                  v-if="getQuestionTeacher(v.examPaperQuestionNumber, 'score4')"
                  class="teacher-list"
                >
                  阅卷老师：仲裁老师（{{
                    getTeacherVal(
                      v.examPaperQuestionNumber,
                      "score4",
                      "teacherName4"
                    )
                  }}）
                </div>
              </div>
            </div>
            <!-- 分项给分 -->
            <div v-if="v.pointList" class="point-list-box">
              <div
                v-for="(item, index) in v.pointList"
                :key="index"
                class="point-list"
              >
                {{ item.questionNum }}-{{ item.name }}题 {{ item.score }}分
                <!-- {{ item }} -->
                <el-input
                  v-model="item.studentScore"
                  placeholder=""
                  :disabled="getDisItem(v)"
                  oninput='value = (value.match(/^[0-9]+(\.[0-9]{0,2})?/g) ?? [""])[0]'
                  @blur="item.studentScore = $event.target.value"
                  @input="setFScore(v)"
                >
                </el-input>
                <div class="teacher-box" v-html="getTeacherList(item.id)"></div>
              </div>
            </div>
          </div>
        </template>
        <!-- {{ studentInfo }} -->
        <div v-if="examInformation.status == 1" class="submit-box">
          <el-button
            type="primary"
            :loading="btnLoading"
            @click="examstudentscoreChangeScore"
          >
            保存
          </el-button>
        </div>
      </div>
    </div>

    <el-dialog title="学生原卷" :visible.sync="dialogVisible" width="70%">
      <div class="model-img">
        <img v-for="(v, i) in imgUrls" :key="i" :src="v" alt="" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getStudentScore,
  studentInfo,
  examstudentscoreChangeScore,
  markStudentInfo,
  scoreTeacher,
} from "@/core/api/exam/examstudentscore";
import { optionItem, optionListT } from "@/core/util/constdata";
import { getObj } from "@/core/api/exam/exam";
import ExamDetailsHeader from "@/components/detailsHeader";
export default {
  name: "ExamStudentScore",
  components: { ExamDetailsHeader },
  data() {
    return {
      answerList: optionItem,
      optionListT: optionListT,
      btnLoading: false,
      examInformation: {},
      studentInfo: {},
      scoreList: [],
      dialogVisible: false,
      imgUrls: [],
      scoreTeacherData: [],
      paperList: [],
    };
  },
  created() {
    // console.log("111111111");
    // this.getStudentScore();
    // this.scoreTeacher();
    this.getStudentInfo(1);
    this.getExamInfo();
  },
  methods: {
    //获取考试基本信息
    getExamInfo() {
      getObj(this.$route.query.examId).then((res) => {
        this.examInformation = res.data.data;
        this.paperList = res.data.data.paperList.map((item) => {
          return {
            label: item.subjectName,
            value: item.paperId,
          };
        });
      });
    },
    // 分项给分  如果
    setFScore(v) {
      // console.log(v);
      let score = 0;
      let array = [];
      v.pointList.map((item) => {
        if (item.score < item.studentScore) {
          array.push(item.questionNum + "-" + item.name);
        }
        score = score + Number(item.studentScore);
      });
      if (array.length > 0) {
        this.$message({
          message: `第${array.join(",")}题分数大于小题分数！`,
          type: "error",
          showClose: true,
          duration: 3000,
        });
      }
      v.studentScore = score;
      // v.studentScore=
    },
    getTeacherVal(questionNum, score, teacherName) {
      let str = "";
      this.scoreTeacherData.forEach((item) => {
        if (item.questionNum == questionNum) {
          str = item[teacherName] + " 得分：" + item[score];
        }
      });
      return str;
    },
    // 获取分项给分列表
    getTeacherList(id) {
      let returnVal = "";
      this.scoreTeacherData.forEach((item) => {
        if (item.id == id) {
          if (item.score1) {
            returnVal =
              returnVal +
              `<div class='teacher-list'>阅卷老师：一评老师（${item.teacherName1}得分：${item.score1}）</div>`;
          }
          if (item.score2) {
            returnVal =
              returnVal +
              `<div class='teacher-list'>阅卷老师：二评老师（${item.teacherName2}得分：${item.score2}）</div>`;
          }
          if (item.score3) {
            returnVal =
              returnVal +
              `<div class='teacher-list'>阅卷老师：三评老师（${item.teacherName3}得分：${item.score3}）</div>`;
          }
          if (item.score4) {
            returnVal =
              returnVal +
              `<div class='teacher-list'>阅卷老师：仲裁老师（${item.teacherName4}得分：${item.score4}）</div>`;
          }
        }
      });

      return returnVal;
    },
    // 获取当前评分老师是否存在
    getQuestionTeacher(questionNum, type) {
      // console.log(questionNum);
      // console.log(type);
      let returnVal = false;
      this.scoreTeacherData.forEach((item) => {
        if (item.questionNum == questionNum) {
          if (item[type]) {
            returnVal = true;
          }
        }
      });
      return returnVal;
    },
    // 获取主观题列表
    scoreTeacher() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
        studentId: this.$route.query.studentId,
        // type: val,
      };
      this.scoreTeacherData = [];
      scoreTeacher(data).then((res) => {
        this.scoreTeacherData = res.data.data;
        // console.log(res);
      });
    },
    // 获取学生答题卡
    markStudentInfo() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
        studentId: this.$route.query.studentId,
        // type: val,
      };
      markStudentInfo(data).then((res) => {
        this.dialogVisible = true;
        this.imgUrls = res.data.data.markUrls.split("，");
        // console.log(res);
      });
    },
    // 获取当前学生 || 下一个学生基本信息
    getStudentInfo(val) {
      // console.log(this.studentInfo);
      // if (this.oldScoreList.length == 0) {
      //   return;
      // }
      let data = {
        examPaperId: this.$route.query.examPaperId,
        studentId: this.$route.query.studentId,
        type: val,
        classNums: this.studentInfo.classNum,
        current: this.studentInfo.pageIndex,
        classOrder: 2,
      };
      studentInfo(data).then((res) => {
        // this.studentInfo
        this.studentInfo = res.data.data;
        let query = JSON.parse(JSON.stringify(this.$route.query));
        query.newTime = new Date().getTime();
        query.studentId = this.studentInfo.studentId;

        this.$router.push({
          query: query,
        });
        this.getStudentScore();
        this.scoreTeacher();
        // console.log(this.studentInfo);
      });
    },
    // 获取当前总分 学生得分
    setScore(type, scoreType) {
      // type 1 客观题 2 主观题
      // scoreType 1 总分 1 学生得分
      let score = 0;
      let quesitonGroupArrStr = "";
      let isOptionId = "";
      this.scoreList.forEach((item) => {
        if (type != 1) {
          if (item.type != 0) {
            if (scoreType == 1) {
              if (isOptionId != item.examQuestionGroup) {
                isOptionId = item.examQuestionGroup;
                score = Number(item.score) + score;
              }
            } else {
              score = Number(item.studentScore) + score;
            }
          }
        } else {
          if (item.type == 0) {
            if (scoreType == 1) {
              let tempScore = Number(item.score);
              if (item.isOption == 1) {
                if (
                  quesitonGroupArrStr.indexOf("," + item.examQuestionGroup) > -1
                ) {
                  tempScore = 0;
                }
                quesitonGroupArrStr += "," + item.examQuestionGroup;
              }
              score = Number(tempScore) + score;
            } else {
              score = Number(item.studentScore) + score;
            }
          }
        }
      });
      let scoreStr = score.toFixed(1);
      if (scoreStr.split(".")[1] == 0) {
        scoreStr = scoreStr.split(".")[0];
      }
      return scoreStr;
    },
    // 获取当前总分 学生得分
    setallScore() {
      // type 1 客观题 2 主观题
      // scoreType 1 总分 1 学生得分
      let score = 0;
      this.scoreList.forEach((item) => {
        score = Number(item.studentScore) + score;
      });
      let scoreStr = score.toFixed(1);
      if (scoreStr.split(".")[1] == 0) {
        scoreStr = scoreStr.split(".")[0];
      }
      return scoreStr;
    },
    examstudentscoreChangeScore() {
      let data = {
        examPaperId: this.$route.query.examPaperId,
        studentId: this.$route.query.studentId,
      };
      let copyList = JSON.parse(JSON.stringify(this.scoreList));
      // console.log(copyList);
      copyList.map((item) => {
        if (item.studentAnswer && item.type == 0) {
          item.studentAnswer.sort();
          item.studentAnswer = item.studentAnswer.join("");
        }
      });
      let choiceList = [];
      let scoreList = [];
      let scoreType = false;
      let scoreTypeList = [];
      let scoreMinTypeList = [];
      let questionArr = [];
      // 本来的数据
      this.oldScoreList.forEach((item) => {
        copyList.forEach((index) => {
          // 先判断客观题  如果客观题
          if (item.type == 0) {
            // 用户修改过的数据
            //  起码一个存在数据  后台返回的数据可能是null  前端转了之后会变为"" item.studentAnswer || index.studentAnswer
            if (
              (item.studentAnswer || index.studentAnswer) &&
              index.examPaperQuestionNumber == item.examPaperQuestionNumber &&
              item.studentAnswer != index.studentAnswer
            ) {
              choiceList.push({
                scoreId: item.scoreId || "",
                oldScore: item.studentScore || "",
                score: index.score,
                answer: index.abType == 2 ? item.answerB : item.answer,
                studentAnswer: index.studentAnswer,
                questionNum: item.examPaperQuestionNumber,
                sheetDetailId: index.sheetDetailId,
              });
            }
          } else {
            // 主观题
            if (
              index.examPaperQuestionNumber == item.examPaperQuestionNumber &&
              item.studentScore != index.studentScore
            ) {
              if (index.studentScore > Number(index.score)) {
                scoreType = true;
                scoreTypeList.push(index.examPaperQuestionNumber);
              }
              if (index.pointList.length > 0) {
                index.pointList.map((i) => {
                  if (i.score < i.studentScore) {
                    scoreMinTypeList.push(i.questionNum + "-" + i.name);
                  }
                  // item.pointList.map((pI) => {
                  //   if (pI.id == i.id && i.studentScore != pI.studentScore) {
                  //     scoreList.push({
                  //       questionNum: item.examPaperQuestionNumber,
                  //       scoreId: item.scoreId || "",
                  //       oldScore: item.studentScore || "",
                  //       newScore: index.studentScore,
                  //       answer: "",
                  //       isOption: index.isOption,
                  //       sheetDetailId: index.sheetDetailId,
                  //       pointList: index.pointList,
                  //     });
                  //   }
                  // });
                });
              }
              scoreList.push({
                questionNum: item.examPaperQuestionNumber,
                scoreId: item.scoreId || "",
                oldScore: item.studentScore || "",
                newScore: index.studentScore,
                answer: "",
                isOption: index.isOption,
                sheetDetailId: index.sheetDetailId,
                pointList: index.pointList,
              });
            }
            if (
              index.examPaperQuestionNumber == item.examPaperQuestionNumber &&
              item.studentScore == index.studentScore
            ) {
              if (index.pointList.length > 0) {
                index.pointList.map((i) => {
                  item.pointList.map((pI) => {
                    if (pI.id == i.id && i.studentScore != pI.studentScore) {
                      let arr = scoreList.map((item) => item.sheetDetailId);
                      // console.log(arr);
                      if (arr.indexOf(item.sheetDetailId) != -1) return;
                      scoreList.push({
                        questionNum: item.examPaperQuestionNumber,
                        scoreId: item.scoreId || "",
                        oldScore: item.studentScore || "",
                        newScore: index.studentScore,
                        answer: "",
                        isOption: index.isOption,
                        sheetDetailId: index.sheetDetailId,
                        pointList: index.pointList,
                      });
                    }
                  });
                });
              }
            }
          }
        });
      });
      copyList.map((index) => {
        copyList.map((item) => {
          // 判断下选做题 问题 选做题 同一组  只能有一组题号存在
          if (
            index.examQuestionGroup == item.examQuestionGroup &&
            index.examPaperQuestionNumber != item.examPaperQuestionNumber &&
            index.isOption
          ) {
            if (index.studentScore && item.studentScore) {
              let arr = [];
              arr.push(index.examPaperQuestionNumber);
              arr.push(item.examPaperQuestionNumber);
              arr = arr.sort();
              let str = arr.join(",");
              if (questionArr.indexOf(str) == -1) {
                questionArr.push(str);
              }
            }
          }
        });
      });

      questionArr = [...new Set(questionArr)];

      if (choiceList.length == 0 && scoreList.length == 0) {
        this.$message({
          message: "请先调整后再保存",
          type: "warning",
        });
        return;
      }
      if (scoreType) {
        this.$message({
          message: `第${scoreTypeList.join(",")}题分数大于小题分数！`,
          type: "error",
          showClose: true,
          duration: 3000,
        });
        return;
      }
      if (scoreMinTypeList.length > 0) {
        this.$message({
          message: `第${scoreMinTypeList.join(",")}题分数大于小题分数！`,
          type: "error",
          showClose: true,
          duration: 3000,
        });
        return;
      }
      if (questionArr.length > 0) {
        this.$message({
          message: `第${questionArr.join(";")}为选做题，只能有一个分数！`,
          type: "error",
          showClose: true,
          duration: 4000,
        });
        return;
      }
      // console.log(scoreList);
      scoreList = scoreList.filter(
        (item) =>
          (item.isOption == 1 && item.newScore != "") || item.isOption != 1
      );
      if (scoreList.length == 0 && choiceList.length == 0) {
        this.$message({
          message: `调整选做题至少应该存在一个分数！`,
          type: "error",
          showClose: true,
          duration: 3000,
        });
        return;
      }
      // console.log(newScoreList);
      data.choiceList = choiceList;
      data.scoreList = scoreList;
      this.btnLoading = true;
      // console.log(data);
      // if (data) {
      //   return;
      // }
      examstudentscoreChangeScore(data)
        .then(() => {
          this.$message({
            showClose: true,
            type: "success",
            message: "成绩调整成功！",
            duration: 1000,
          });
          this.btnLoading = false;
          this.getStudentScore();
          this.scoreTeacher();
          this.getStudentInfo(1);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    setDis(v) {
      // console.log(v);
      let type = true;
      if (!v.abType || v.abType == 0 || v.abType == 1 || v.abType == -1) {
        // console.log(v.answer);
        if (v.answer && v.answer.length > 0) {
          type = false;
        }
      } else {
        if (v.answerB && v.answerB.length > 0) {
          type = false;
        }
      }
      if (this.examInformation.status != 1) {
        type = true;
      }
      if (!v.abType) {
        type = true;
      }
      return type;
    },
    getDisItem(v) {
      // console.log(v);
      let type = false;
      if (this.examInformation.status != 1) {
        type = true;
      }
      // 如果是导入成绩  不能修改
      if (!v.abType) {
        type = true;
      }
      return type;
    },
    getDis(v) {
      // console.log(v);
      let type = false;
      if (this.examInformation.status != 1) {
        type = true;
      }
      // 如果是导入成绩  不能修改
      if (!v.abType) {
        type = true;
      }
      // 如果是分项给分  就禁用
      if (v.pointList && v.pointList.length > 0) {
        type = true;
      }
      return type;
    },
    setClass(v, indexVal) {
      let type = false;
      // console.log(v.answer);
      if (!v.abType || v.abType == 0 || v.abType == 1 || v.abType == -1) {
        if (v.answer && v.answer.indexOf(indexVal) != -1) {
          type = true;
        }
        if (v.answer && v.answer.indexOf("?") != -1) {
          type = true;
        }
      } else {
        if (v.answerB && v.answerB.indexOf(indexVal) != -1) {
          type = true;
        }
        if (v.answerB && v.answerB.indexOf("?") != -1) {
          type = true;
        }
      }
      return type;
      // if
    },
    getStudentScore() {
      // console.log(this.studentInfo);
      let data = {
        examPaperId: this.$route.query.examPaperId,
        studentId: this.$route.query.studentId,
        // examPaperId: 1001,
        // studentId: 19838,
      };
      getStudentScore(data).then((res) => {
        this.oldScoreList = JSON.parse(JSON.stringify(res.data.data));
        let scoreList = JSON.parse(JSON.stringify(res.data.data));
        // 组装页面显示的数据  选择题  大题
        let list = scoreList.map((item) => {
          // 如果是选择题
          if (item.type == 0) {
            // a卷或者普通考试答案
            if (item.answer) {
              if (item.isSpecial < 1) {
                item.answer = item.answer.split("");
              } else {
                let oldOption = item.answer.split(";");
                let optionArr = [];
                oldOption.forEach((i) => {
                  optionArr.push(i.split(",")[0]);
                });
                item.answer = optionArr;
              }
            } else {
              item.answer = [];
            }
            // b卷答案
            if (item.answerB) {
              // item.answerB = item.answerB.split("");
              if (item.isSpecialB < 1) {
                item.answerB = item.answerB.split("");
              } else {
                let oldOption = item.answerB.split(";");
                let optionArr = [];
                oldOption.forEach((i) => {
                  optionArr.push(i.split(",")[0]);
                });
                item.answerB = optionArr;
              }
            } else {
              item.answerB = [];
            }
            // 学生答案
            if (item.studentAnswer) {
              item.studentAnswer = item.studentAnswer.split("");
            } else {
              item.studentAnswer = [];
            }
          }
          // 如果是主观题
          if (item.pointList && item.pointList.length > 0) {
            item.pointList.map((index) => {
              if (index.studentScore === undefined) index.studentScore = "";
            });
          }
          return item;
        });
        this.scoreList = list;
        // console.log(this.scoreList);
      });
    },
    goBack() {
      // console.log(this.studentInfo);
      this.$router.push({
        name: this.$route.query.urlName,
        query: {
          examId: this.$route.query.examId,
          paperId: this.$route.query.examPaperId,
          routerName: this.$route.query.typeName,
        },
      });
      // this.$router.go(-1);
    },
    initData() {
      // let Base64 = require("js-base64").Base64;
      // this.studentInfo = JSON.parse(Base64.decode(this.$route.query.code));
      // console.log(this.studentInfo);/
    },
  },
};
</script>
<style lang="scss" scoped>
.ExamStudentScore {
  padding: 24px 0 60px;
  .point-list {
    display: flex;
    align-items: center;
    margin-top: 12px;
    padding-left: 12px;
    .el-input {
      display: block;
      margin-left: 20px;
      width: 66px;
    }
  }
  .teacher-list {
    margin-left: 24px;
    color: #595959;
  }
  .model-img {
    max-height: calc(100vh - 300px);
    overflow-y: scroll;
    img {
      width: 100%;
    }
  }
  .submit-box {
    text-align: center;
    margin-top: 24px;
  }
  .question-cnt {
    padding: 24px;
    background-color: #ffffff;
    margin-top: 24px;
    .teacher-box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      ::v-deep > div {
        margin-left: 12px;
      }
    }
    .question-list-subjective {
      display: flex;
      // flex-wrap: wrap;
      align-items: center;
      margin-top: 24px;

      .question-list-title {
        flex-shrink: 0;
        min-width: 120px;
        text-align: right;
      }
      .el-input {
        width: 66px;
        margin-left: 20px;
        flex-shrink: 0;
      }
    }
    .question-list-box {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 24px 4px 0 24px;
      .question-list {
        display: flex;
        // margin-right: 60px;
        min-width: 300px;

        .el-checkbox {
          margin-bottom: 24px;
          margin-right: 20px;
        }
        ::v-deep .is-checked + .el-checkbox__label {
          color: #f56c6c;
        }
        ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
          background-color: #f56c6c;
          border-color: #f56c6c;
        }
        ::v-deep .on .el-checkbox__label {
          color: $success-color;
        }
        ::v-deep .on .el-checkbox__input.is-checked .el-checkbox__inner {
          background-color: $success-color;
          border-color: $success-color;
        }
        .question-list-title {
          min-width: 42px;
          flex-shrink: 0;
          text-align: right;
          padding-right: 10px;
        }
      }
    }
    .question-header {
      padding: 12px;
      background: #f5f7fa;
      font-weight: 500;
      color: #080a09;
      em {
        color: #f56c6c;
      }
      span {
        font-size: 14px;
        font-weight: 100;
        color: #3e4551;
        margin-left: 14px;
      }
    }
  }

  .header-box {
    background-color: #ffffff;
    padding: 18px;
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    .header-btn-box {
      .el-button {
        display: block;
        &:last-child {
          margin-top: 7px;
          margin-left: 0;
        }
      }
    }
    .student-info-list {
      display: flex;
      width: 33vw;
      justify-content: space-between;
      align-items: flex-start;
      text-align: center;
      img {
        width: 15px;
      }
      span {
        color: #9b9b9f;
        font-size: 12px;
      }
      p {
        margin: 10px 0 8px;
        font-weight: 500;

        font-size: 18px;
      }
    }
    .title {
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      // margin-bottom: 24px;
      // width: 100%;
      .el-icon-arrow-left {
        cursor: pointer;
      }
    }
  }
}
</style>
